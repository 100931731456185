
import Field from "./controls.filter.Field.js";

export default class FieldDistinct extends Field {
    constructor(options) {
        super(options);

        if (!this.filter.dataObject.getFields(this.field)) {
            console.warn(`MobileFilter: Field does not exist in data object '${this.field}'`);
        }
    }

    loadStorage() {
        super.loadStorage();
        if (!Array.isArray(this.storage.values2)) {
            this.storage.values2 = [];
        }
    }

    setTempValue(value) {
        this.tempValue = value;
    }

    applyTempValue() {
        if (this.tempValue) {
            this.storage.values2 = this.tempValue.filter(e => e.selected).map(value => {
                return {
                    name: value.name,
                    value: value.value,
                };
            });
            this.filter.update();
            this.tempValue = null;
        }
    }

    setValues(values) {
        this.storage.values2 = values;
        this.saveStorage();
    }

    getFilterString() {
        if (!this.storage.values2.length) {
            return "";
        }
        return `[${this.field}] IN (${this.storage.values2.map(e => `'${e.value}'`).join(",")})`;
    }

    getItem() {
        return this.filter.dataObject.filterObject.getItem(this.field);
    }

    isTempChanged() {
        if (this.tempValue) {
            for (let value of this.tempValue) {
                if (value.selected !== value._selected) {
                    return true;
                }
            }
        }
        return false;
    }

    async getDistinctValuesForItem(item, search) {
        if (item.distinctHandler) {
            item.distinctHandler.setDataObject(this.filter.dataObject);

            let reload = false;
            if (item.distinctHandler.search !== search) {
                item.distinctHandler.search = search;
                reload = true;
            }
            // if (item.distinctHandler.targetColumn !== this.targetColumn) {
            //     item.distinctHandler.targetColumn = this.targetColumn;
            //     reload = true;
            // }

            // cache fix

            const rows = await item.distinctHandler.getData(reload);

            return rows.map(row => {
                return {
                    name: row[this.field],
                    value: row[item.distinctTargetColumn || item.field],
                    count: row.Count,
                    selected: false,
                    _selected: false,
                    ...row,
                };
            });
        } else if (item.distinctType === "bit") {
            return [
                {
                    name: $t("Is true"),
                    value: true,
                    count: -1,
                    selected: false,
                    _selected: false,
                },
                {
                    name: $t("Is false"),
                    value: false,
                    count: -1,
                    selected: false,
                    _selected: false,
                },
            ];
        } else {
            console.error("invalid item", item);
        }
    }

    async getDistinctValues(search) {
        const item = this.getItem();
        const values = await this.getDistinctValuesForItem(item, search);

        for (let svalue of this.storage.values2) {
            const value = values.filter(e => e.name === svalue.name)[0];
            if (value) {
                value.selected = true;
                value._selected = true;
            } else {
                values.push({
                    ...svalue,
                    count: 0,
                    selected: true,
                    _selected: true,
                });
            }
        }

        if (this.sortFunction) {
            values.sort(this.sortFunction);
        }

        return values;
    }

    getFilterItems() {
        const item = this.filter.dataObject.filterObject.getItem(this.field);
        const values = this.storage.values2.map(e => e.value).filter(e => typeof e === (item.distinctTargetColumnType || "string"));
        if (!values.length) {
            return [];
        }
        return [
            {
                column: item.distinctTargetColumn || item.column,
                operator: "inlist",
                value: values,
            }
        ];
    }

    getFilterText() {
        if (!this.storage.values2.length) {
            return "";
        }

        let text = "";

        if (this.storage.values2.length >= 2) {
            text = text + `(${this.storage.values2.length}) `;
        }

        text = text + this.storage.values2.map(e => e.name).join(", ");

        return text;
    }

    clear() {
        this.storage.values2 = [];
        this.saveStorage();
    }

    // to show a single pill inside the filter that you can cancel
    getPill() {
    }

    // to show multiple pills below search input that you can cancel
    getPills() {
        // values
        return this.storage.values2.map(value => {
            const info = {
                text: value.name,
                click: () => {
                    this.filter.showFieldSheet = true;
                    this.filter.field = this;
                },
                close: () => {
                    this.storage.values2 = this.storage.values2.filter(e => e !== value);
                    this.filter.update();
                },
            };
            if (this.getItem().distinctType === "bit") {
                info.text = this.name + ": " + info.text;
            }
            return info;
        });
    }
}
